import React from 'react'
import { graphql } from 'gatsby'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import { normalizeArray } from 'lib/graphql/normalize'
import JSONLD from 'components/JSONLD'
import DefaultLayout from 'layouts/DefaultLayout'
import CategoryBanner from 'views/general/public/ContentHub/components/CategoryBanner'
import Pagination from 'views/general/public/ContentHub/components/Pagination'
import { ARTICLES_PER_PAGE } from 'views/general/public/ContentHub/constants'
import ArticlesList from './components/ArticlesList'

interface ArticleCategoryProps {
  data: {
    category: {
      title: string
      fields: {
        totalBlocks: number
      }
    }
    categoryGroup: {
      edges: {
        node: GatsbyTypes.ContentfulContentHubCategoryGroup
      }[]
    }
    articleBlocks: {
      edges: {
        node: {
          id: string
          articles: GatsbyTypes.ContentfulArticle[]
        }
      }[]
    }
  }
  pageContext: {
    slug: string
    categoryGroupSlug: string
    categoryGroupTitle?: string
    pageType: string
    pageIndex: number
    limit: number
    skip: number
  }
}

const ArticleCategory = ({
  data,
  pageContext,
}: ArticleCategoryProps): React.ReactElement => {
  const { category, articleBlocks, categoryGroup } = data
  const articles = articleBlocks.edges.reduce(
    (list, articleBlock) => [...list, articleBlock.node.articles],
    [] as GatsbyTypes.ContentfulArticle[][]
  )
  const totalBlocks = category?.fields.totalBlocks
  const totalPages = Math.ceil(totalBlocks / ARTICLES_PER_PAGE)
  const isGroupWithSingleCategory = !!pageContext.categoryGroupTitle
  const title = pageContext.categoryGroupTitle || category?.title
  const breadcrumbs =
    normalizeArray<GatsbyTypes.ContentfulContentHubCategoryGroup>(categoryGroup)
  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />
      <DefaultLayout title={title}>
        <CategoryBanner
          title={title}
          breadcrumbs={isGroupWithSingleCategory ? [] : breadcrumbs}
        />
        <Wrapper background={COLOR.WHITE} padding={['XXL', 0, 'L']}>
          <ArticlesList
            articles={articles}
            hasLargeArticle={pageContext.pageIndex === 1}
          />

          {totalPages > 1 && (
            <Pagination
              currentPage={pageContext.pageIndex}
              totalPages={totalPages}
              pageSlug={pageContext.categoryGroupSlug || pageContext.slug}
            />
          )}
        </Wrapper>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query ArticleCategoryBySlug($slug: String!, $skip: Int, $limit: Int) {
    category: contentfulContentHubArticleCategory(slug: { eq: $slug }) {
      title
      fields {
        totalBlocks
      }
    }
    categoryGroup: allContentfulContentHubCategoryGroup(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      limit: 1
    ) {
      edges {
        node {
          title
          shortTitle
          slug
        }
      }
    }
    articleBlocks: allArticleBlock(
      filter: { category: { eq: $slug } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          articles {
            contentful_id
            type
            title
            description {
              description
            }
            image {
              file {
                url
              }
            }
            slug
          }
        }
      }
    }
    articleBlocks: allArticleBlock(
      filter: { category: { eq: $slug } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          articles {
            contentful_id
            type
            title
            description {
              description
            }
            image {
              file {
                url
              }
            }
            slug
          }
        }
      }
    }
  }
`

export default ArticleCategory
