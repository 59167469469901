import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowLeftIcon, ArrowRightIcon, Button, Wrapper } from '@farewill/ui'
import { BORDER, BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import PATHS from 'lib/navigation/paths'
import BreakpointSwitch from 'components/BreakpointSwitch'

const EDGE_PAGE_RANGE = 2
const MIDDLE_PAGE_RANGE = 1
const SHOW_ALL_PAGES_LIMIT = 4

const StyledContainer = styled(Wrapper)`
  display: flex;
  justify-content: center;

  ${screenMax.s`
    flex-direction: column;
    align-items: center;
  `}
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  border-radius: ${BORDER.RADIUS.S};
  box-shadow: ${BORDER.SHADOW.M};
`

const StyledFlexWrapper = styled(Wrapper)`
  display: flex;
`

const StyledNumberButton = styled(Button.Plain)<{ $active: boolean }>`
  margin: 0 ${GTR.XXS};

  &:hover {
    background-color: ${COLOR.ACCENT.PRIMARY_60};
  }

  ${screenMax.s`
    padding: 11px 14px;
  `}

  ${({ $active }) =>
    $active &&
    `
      background-color: ${COLOR.ACCENT.PRIMARY};

      &:hover {
        background-color: ${COLOR.ACCENT.PRIMARY};
      }
  `}
`

const StyledArrowButton = styled(Button.Plain)<{
  $disabled: boolean
  $left?: boolean
}>`
  ${screenMax.s`
  padding: ${GTR.M};
  width: 160px;
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    pointer-events: none;
    color: ${COLOR.GREY.LIGHT};
  `}

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(${({ $left }) => ($left ? '-8px' : '8px')});
    }
  }
`

const StyledDivider = styled(Wrapper)`
  border-right: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledDots = styled(Button.Plain)`
  cursor: default;
  pointer-events: none;

  ${screenMax.s`
    padding: 11px 14px;
  `}
`

const Dots = () => <StyledDots tag="p">...</StyledDots>

const Pagination = ({
  currentPage,
  totalPages,
  pageSlug,
}: {
  currentPage: number
  totalPages: number
  pageSlug: string
}): React.ReactElement => {
  const [pageNumbersToDisplay, setPageNumbersToDisplay] = useState<number[]>([])
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  useEffect(() => {
    const middlePageNumbers = []
    const range =
      isFirstPage || isLastPage ? EDGE_PAGE_RANGE : MIDDLE_PAGE_RANGE

    for (let i = currentPage - range; i <= currentPage + range; i += 1) {
      if (i > 1 && i < totalPages) {
        middlePageNumbers.push(i)
      }
    }
    setPageNumbersToDisplay([1, ...middlePageNumbers, totalPages])
  }, [currentPage])

  const showDots = (pageNumber: number, index: number) =>
    totalPages > SHOW_ALL_PAGES_LIMIT &&
    ((currentPage > 1 + EDGE_PAGE_RANGE && pageNumber === 1) ||
      (currentPage < totalPages - EDGE_PAGE_RANGE &&
        index === pageNumbersToDisplay.length - 2))

  const getPageLink = (pageNumber: number) =>
    `${PATHS.GENERAL.CONTENT_HUB}/${pageSlug}/${
      pageNumber === 1 ? '' : `page-${pageNumber}`
    }`

  return (
    <StyledContainer container>
      <BreakpointSwitch
        breakAt={BREAKPOINT.S}
        aboveComponent={
          <StyledWrapper bordered background={COLOR.WHITE} padding="S">
            <StyledArrowButton
              tag={Link}
              to={isFirstPage ? null : getPageLink(currentPage - 1)}
              $disabled={isFirstPage}
              $left
            >
              <ArrowLeftIcon />
            </StyledArrowButton>
            {pageNumbersToDisplay.map((pageNumber, index) => (
              <div key={pageNumber}>
                <StyledNumberButton
                  tag={Link}
                  $active={currentPage === pageNumber}
                  to={getPageLink(pageNumber)}
                >
                  {pageNumber}
                </StyledNumberButton>
                {showDots(pageNumber, index) && <Dots />}
              </div>
            ))}
            <StyledArrowButton
              tag={Link}
              $disabled={isLastPage}
              to={isLastPage ? null : getPageLink(currentPage + 1)}
            >
              <ArrowRightIcon />
            </StyledArrowButton>
          </StyledWrapper>
        }
        belowComponent={
          <>
            <StyledFlexWrapper>
              {pageNumbersToDisplay.map((pageNumber, index) => (
                <div key={pageNumber}>
                  <StyledNumberButton
                    tag={Link}
                    $active={currentPage === pageNumber}
                    to={getPageLink(pageNumber)}
                  >
                    {pageNumber}
                  </StyledNumberButton>
                  {showDots(pageNumber, index) && <Dots />}
                </div>
              ))}
            </StyledFlexWrapper>
            <StyledWrapper background={COLOR.WHITE} margin={['M', 0, 0]}>
              <StyledArrowButton
                tag={Link}
                $disabled={isFirstPage}
                to={isFirstPage ? null : getPageLink(currentPage - 1)}
                icon={<ArrowLeftIcon />}
                iconOnLeft
                $left
              >
                Prev
              </StyledArrowButton>
              <StyledDivider />
              <StyledArrowButton
                tag={Link}
                $disabled={isLastPage}
                to={isLastPage ? null : getPageLink(currentPage + 1)}
                icon={<ArrowRightIcon />}
              >
                Next
              </StyledArrowButton>
            </StyledWrapper>
          </>
        }
      />
    </StyledContainer>
  )
}

export default Pagination
